import { IPostWrapper } from '@/interfaces/wrapper';
import { BigThumbnailPostCard, HorizontalPostCard } from '../../PostCard';

const TwoLargeFourSmall = ({ data }: IPostWrapper) => {
  return (
    <div>
      <div className="flex gap-4 lg:gap-4 mb-3 lg:mb-4">
        <BigThumbnailPostCard {...data?.[0]} />
        <BigThumbnailPostCard {...data?.[1]} />
      </div>

      <div className="grid lg:grid-cols-2 gap-4 lg:gap-x-8 lg:gap-y-2">
        {data?.slice(2, 4)?.map((item, index) => {
          return (
            <div className="col-span-1" key={index}>
              <HorizontalPostCard {...item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TwoLargeFourSmall;

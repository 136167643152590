import { IPostWrapper } from '@/interfaces/wrapper';
import { VerticalPostCard } from '../../PostCard';

const ThreeImages = ({ data }: IPostWrapper) => {
  return (
    <div className="flex lg:justify-between lg:gap-[18px] gap-3">
      {data?.slice(0, 3)?.map((item, index) => {
        return (
          <div className="w-full" key={index}>
            <VerticalPostCard {...item} />
          </div>
        );
      })}
    </div>
  );
};

export default ThreeImages;

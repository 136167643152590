import React from 'react';
import { BigThumbnailPostCard } from '../../PostCard';
import { IPostWrapper } from '@/interfaces/wrapper';

const FourItems = ({ data }: IPostWrapper) => {
  return (
    <div className="flex flex-col gap-3 lg:gap-5">
      {data?.slice(0, 4)?.map((item, index) => {
        return <BigThumbnailPostCard key={index} {...item} horizontal />;
      })}
    </div>
  );
};

export default FourItems;

import { CategorySlider } from '..';
import { useMemo } from 'react';
import Link from 'next/link';
import { Layout_Page_Tops_Enum, Layouts_Enum } from '@/generated/graphql';
import { OneLargeFourSmall, TwoLargeFourSmall } from './components';
import ThreeImages from './components/ThreeImages';
import FourItems from './components/FourItems';
import { ITabWrapper } from '@/interfaces/wrapper';
import TwoLargeTwoSmall from '@/components/Shared/TabWrapper/components/TwoLargeTwoSmall';

export enum DATA_WRAPPER_OPTION_ENUM {
  PART_INFO = 'PART_INFO',
  FULL_INFO = 'FULL_INFO',
}

const SkelatonTabWrapper = () => {
  return (
    <>
      <div className="animate-pulse bg-[#E4E4E4] py-5"></div>
      {Array.from({ length: 4 }).map((_, index) => {
        return (
          <div key={index} className="h-40 w-full rounded-lg flex gap-5">
            <div className="w-64 h-full bg-gray-200 animate-pulse"></div>
            <div className="flex flex-col gap-5 flex-1">
              <div className="w-full h-10 bg-gray-200 animate-pulse"></div>
              <div className="w-full h-10 bg-gray-200 animate-pulse"></div>
              <div className="w-full h-10 bg-gray-200 animate-pulse"></div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const TabWrapper = ({
  layout,
  data,
  showViewMore = true,
  category,
  isLoading,
}: ITabWrapper) => {
  const DataWrapper = useMemo(() => {
    switch (layout) {
      case Layouts_Enum.OneLargeFourSmall:
        return <OneLargeFourSmall data={data} />;

      case Layouts_Enum.TwoLargeFourSmall:
        return <TwoLargeFourSmall data={data} />;

      case Layouts_Enum.FourItems:
        return <FourItems data={data} />;

      case Layouts_Enum.ThreeImages:
        return <ThreeImages data={data} />;

      case Layout_Page_Tops_Enum.OneLargeFiveSmall:
        return <OneLargeFourSmall data={data} />;

      case Layout_Page_Tops_Enum.TwoLargeTwoSmall:
        return <TwoLargeTwoSmall data={data} />;

      default:
        return <OneLargeFourSmall data={data} />;
    }
  }, [layout, data]);

  return (
    <div
      className="flex flex-col gap-4"
      style={{
        animation: 'animate-fadein 0.5s',
      }}
    >
      {isLoading ? (
        <SkelatonTabWrapper />
      ) : (
        <>
          <div className="bg-[#E4E4E4] py-2 lg:px-6 px-4">
            <CategorySlider
              parent={category?.parent}
              childrenCategories={category?.childrenCategories}
              isTabWrapper
            />
          </div>

          {DataWrapper}

          {showViewMore && (
            <div className="text-end">
              <Link
                href={`/${category?.parent?.slug}`}
                className="w-fit text-xs font-black text-DarkBlue hover:underline hover:decoration-solid hover:text-primary"
              >
                Xem thêm
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TabWrapper;
